
import HeaderSecond from '../HeaderSecond'
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import BreadcrumbBg from '../img/banner/breadcrumb-bg.jpg'
import axios from '../axios'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import registerationsImg from '../../src/img/other/agenda.png';
import ticketImg from '../../src/img/other/ticket.png';
import pageviewImg from '../../src/img/other/pageviews.png';
import netEarningsImg from '../../src/img/other/earnings.png';
// import demoChart from '../../src/img/other/demochart.png';
import conversionImg from '../../src/img/other/conversion-rate.png';
import paymentHistoryImg from '../../src/img/other/payment-history.png';
import totalRegImg from '../../src/img/other/total-registrations.png';
import soldRegImg from '../../src/img/other/sold-registrations.png';
import participantsImg from '../../src/img/other/participants.png';
import noRecordFound from '../../src/img/other/noRecordFound.png';

import remittanceAmount from '../../src/img/other/remittance-amount.png';
import receivableToOrg from '../../src/img/other/receivabletoorg.png';
import paymentGateway from '../../src/img/other/payment-gateway.png';
import convenienceFee from '../../src/img/other/convenience-fee.png';
import PieChart from './PieChart';
// noRecordFound.png
import DonutChart from './DonutChart';
import BarChart from './BarChart';
import {UserAccessRights} from '../Custom';

const CustomTooltip = ({ text }) => (
    <div className="form-question-tooltip">
        {text}
    </div>
);

const EventDashbaord = () => {
    const location = useLocation();
    const { state } = location;
    const EventName = state ? state.event_name : '';
    localStorage.setItem("EventName", EventName)
    // console.log(state.event_name);

    const navigate = useNavigate();
    const { EventId } = useParams();
    const [ApiCall, setApiCall] = useState(true);
    const [NetSales, setNetSales] = useState('')
    const [TotalRegistration, setTotalRegistration] = useState('')
    const [TotalAmount, setTotalAmount] = useState('');
    const [Filter, setFilter] = useState("");
    const [TotalTickets, setTotalTickets] = useState("");
    const [TotalBookedTickets, setTotalBookedTickets] = useState("");
    const [SuccessPercentage, setSuccessPercentage] = useState("");
    const [Tooltip, setTooltip] = useState("");
    const [BookingData, setBookingData] = useState([]);
    const [BarChartData, setBarChartData] = useState([]);
    const [FromDate, setFromDate] = useState('');
    const [ToDate, setToDate] = useState('');
    const [TicketData, setTicketData] = useState([]);
    const [Ticket, setTicket] = useState('');
    const [MaleCount, setMaleCount] = useState(0);
    const [FemaleCount, setFemaleCount] = useState(0);
    const [OtherCount, setOtherCount] = useState(0);
    const [UtmCode, setUtmCode] = useState([]);
    const [CouponCodes, setCouponCodes] = useState([]);
    const [AgeCategory, setAgeCategory] = useState([]);
    const [TotalPageViews, setTotalPageViews] = useState(0);
    const [CustomQArray, setCustomQArray] = useState([]);
    
    const [RemittanceAmount, setRemittanceAmount] = useState(0);
    const [OrganiserAmount, setOrganiserAmount] = useState(0);
    const [TotalPaymentGateway, setTotalPaymentGateway] = useState(0);
    const [TotalConvenience, setTotalConvenience] = useState(0);
    const [TotalParticipant, setTotalParticipant] = useState(0);

    // create by neha on 10-12-24
    const [RemittanceData, setRemittanceData] = useState([]);
    const [MarketingData, setMarketingData] = useState([]);
    

    // console.log(EventId)
    const handleBackToHome = () => {
        const CountryCode = localStorage.getItem('CountryCode');
        const CountryName = localStorage.getItem('Country');
        navigate('/' + CountryCode + '/' + CountryName);
    }

    useEffect(() => {
        if (ApiCall) {
            const payload = {
                "event_id": EventId,
                'filter': Filter,
                "from_date": FromDate,
                "to_date": ToDate,
                "Ticket": Ticket
            };
            axios.post("get_insights", payload)
                .then((response) => {
                    // console.log(response)
                    if (response.status === 200) {
                        setNetSales(response.data.data.NetSales);
                        setTotalParticipant(response.data.data.TotalParticipant);
                        setTotalRegistration(response.data.data.TotalRegistration);
                        setTotalAmount(response.data.data.TotalAmount);
                        setTotalTickets(response.data.data.TotalTickets);
                        setTotalBookedTickets(response.data.data.TotalBookedTickets);
                        setSuccessPercentage(response.data.data.SuccessPercentage);
                        setTicketData(response.data.data.TicketData);
                        setTotalPageViews(response.data.data.TotalPageViews);

                        setRemittanceAmount(response.data.data.RemittanceAmount);
                        setOrganiserAmount(response.data.data.OrganiserAmount);
                        setTotalPaymentGateway(response.data.data.TotalPaymentGateway);
                        setTotalConvenience(response.data.data.TotalConvenience);
                        
                        // create by neha on 10-12-24
                        setRemittanceData(response.data.data.RemittanceDetails);
                        setMarketingData(response.data.data.MarketingDetails);

                        // Make the second API call only after the first one succeeds
                        return axios.post("get_category_wise_data", payload);
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        // console.log(response.data.data.CouponCodes)
                        setBookingData(response.data.data.BookingData);
                        setBarChartData(response.data.data.FinalBarChartData);
                        setMaleCount(response.data.data.maleCount);
                        setFemaleCount(response.data.data.femaleCount);
                        setOtherCount(response.data.data.otherCount);
                        setUtmCode(response.data.data.utmCode);
                        setCouponCodes(response.data.data.CouponCodes);
                        setAgeCategory(response.data.data.ageCategory);
                        setCustomQArray(response.data.data.CountArray);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setApiCall(false);
                });
        }
    }, [EventId, Filter, ApiCall, FromDate, ToDate, Ticket]);

    // console.log(CustomQArray)
    const HandleClear = (e) => {
        e.preventDefault();
        setTicket(''); setFromDate(''); setToDate(''); setFilter('');
        setApiCall(true);
    }

    //-------------- To check user access rights
    const AccessRightFlag = localStorage.getItem("Access_Right_Flag");
    useEffect(() => {
        if(AccessRightFlag === '1'){
            const access = UserAccessRights({module_name: "Insight"});
            if(access === true){
            alert("You don't have any access to this module");
            navigate('/');
            }
        }
    }, []);

    return (
        <>
            <HeaderSecond />
            <ToastContainer theme="colored" />

            <main className="main__content_wrapper">
                <section className="breadcrumb__section breadcrumb__bg" style={{ backgroundImage: `url(${BreadcrumbBg})` }} >
                    <div className="container">
                        <div className="row row-cols-1">
                            <div className="col">
                                <div className="breadcrumb__content">
                                    <h1 className="breadcrumb__content--title text-white mb-10">
                                        {EventName}
                                    </h1>
                                    <ul className="breadcrumb__content--menu d-flex">
                                        <li className="breadcrumb__content--menu__items">
                                            <div className="text-white" onClick={(e) => handleBackToHome(e)}>
                                                Home
                                            </div>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">My Event</span>
                                        </li>
                                        <li className="breadcrumb__content--menu__items">
                                            <span className="text-white">{EventName}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='section--padding py-5'>
                    <div className='container'>

                        <div className='dashboard-card border-radius-10 mb-5 p-4'>
                            <div className="row my-2">
                                <div className="col-sm-6 d-flex align-items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" /></svg>
                                    <p className='h4'>Search Filter</p>
                                </div>
                                <div className="col-sm-6 d-flex align-items-center justify-content-end gap-3">
                                    <div>
                                        {
                                            (Filter !== "" || Ticket !== "" || FromDate !== "" || ToDate !== "") ?
                                                <p className="account__login--btn secondary__btn" onClick={(e) => {
                                                    HandleClear(e);
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M440-122q-121-15-200.5-105.5T160-440q0-66 26-126.5T260-672l57 57q-38 34-57.5 79T240-440q0 88 56 155.5T440-202v80Zm80 0v-80q87-16 143.5-83T720-440q0-100-70-170t-170-70h-3l44 44-56 56-140-140 140-140 56 56-44 44h3q134 0 227 93t93 227q0 121-79.5 211.5T520-122Z" /></svg> Clear</p>
                                                : null
                                        }
                                    </div>
                                    <div onClick={(e) => { navigate('/edit_event')}} style={{cursor: 'pointer'}}>
                                        <p className="account__login--btn secondary__btn"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" /></svg> Back</p>
                                    </div>
                           
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-sm-3'>
                                    <div className="form-floating">
                                        <select className="form-control" name="filter" value={Filter} onChange={(e) => {
                                            setFilter(e.target.value); setApiCall(true);
                                        }}>
                                            <option value="">-- Select Filter --</option>
                                            <option value="today">Today</option>
                                            <option value="week">This Week</option>
                                            <option value="month">This Month</option>
                                        </select>
                                        <label>Filter </label>
                                    </div>
                                </div>
                                <div className='col-sm-3 my-2'>
                                    <div className="form-floating">
                                        <select className="form-control" name="category" value={Ticket} onChange={(e) => { setTicket(e.target.value); setApiCall(true); }}>
                                            <option value="">-- Select Category --</option>
                                            {
                                                TicketData && TicketData.length > 0 ? TicketData.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.ticket_name}</option>
                                                }) : null
                                            }
                                        </select>
                                        <label>Category </label>
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-floating">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="event-date"
                                            value={FromDate}
                                            onChange={(e) => {
                                                setFromDate(e.target.value);
                                                setApiCall(true);
                                            }}
                                        />
                                        <label className="select-label">Date From <span className="checkout__input--label__star">*</span></label>
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div className="form-floating">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="event-date"
                                            value={ToDate}
                                            onChange={(e) => {
                                                setToDate(e.target.value);
                                                setApiCall(true);
                                            }}
                                        />
                                        <label className="select-label">Date To <span className="checkout__input--label__star">*</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row'>

                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10' style={{ position: "relative" }}>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Registrations</p>
                                                <div className="px-3" onMouseOver={() => setTooltip(1)}
                                                    onMouseLeave={() => setTooltip('')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                    {Tooltip === 1 && <CustomTooltip text="This is an reagistations tooltip" />}
                                                </div>
                                            </div>
                                            <h3 className='title-value'>{TotalRegistration}</h3>
                                            <p className='text-primary py-3 cursor-pointer' style={{ position: "absolute", bottom: "0" }} onClick={(e) => { navigate('/event_registrations/' + EventId, { state: { event_name: EventName } }); }}>View Details</p>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${totalRegImg}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10' style={{ position: "relative" }}>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            {/* <p className='m-0 pb-3 title'>Total Participants</p> */}
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Participants</p>
                                                <div className="px-3" onMouseOver={() => setTooltip(2)}
                                                    onMouseLeave={() => setTooltip('')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                    {Tooltip === 2 && <CustomTooltip text="This shows Total Participants" />}
                                                </div>
                                            </div>
                                            <h3 className='title-value'>{TotalParticipant}</h3>
                                            <p className='text-primary py-3 cursor-pointer' style={{ position: "absolute", bottom: "0" }} onClick={(e) => { navigate('/event_netsale/' + EventId, { state: { event_name: EventName } }); }}>View Details</p>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${participantsImg}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10' style={{ position: "relative" }}>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            {/* <p className='m-0 pb-3 title'>Net Sales</p> */}
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Net Sales</p>
                                                <div className="px-3" onMouseOver={() => setTooltip(3)}
                                                    onMouseLeave={() => setTooltip('')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                    {Tooltip === 3 && <CustomTooltip text="This shows Net Sales" />}
                                                </div>
                                            </div>
                                            <h3 className='title-value'>{NetSales}</h3>
                                            {/* <p className='text-primary py-3 cursor-pointer' style={{ position: "absolute", bottom: "0" }} onClick={(e) => { navigate('/event_netsale/' + EventId, { state: { event_name: EventName } }); }}>View Details</p> */}
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${ticketImg}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10'>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            {/* <p className='m-0 pb-3 title'>Net Earnings</p> */}
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Total Amount Collected</p>
                                                <div className="px-3" onMouseOver={() => setTooltip(4)}
                                                    onMouseLeave={() => setTooltip('')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                    {Tooltip === 4 && <CustomTooltip text="This shows Net Earnings" />}
                                                </div>
                                            </div>
                                            <h3 className='title-value' style={{fontSize: '25px'}}>
                                                ₹ {TotalAmount}
                                            </h3>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${netEarningsImg}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10'>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            {/* <p className='m-0 pb-3 title'>Conversion Rate</p> */}
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Conversion Rate</p>
                                                <div className="px-3" onMouseOver={() => setTooltip(5)}
                                                    onMouseLeave={() => setTooltip('')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                                                    {Tooltip === 5 && <CustomTooltip text="This shows Conversion Rate" />}
                                                </div>
                                            </div>
                                            <h3 className='title-value'>{SuccessPercentage} %</h3>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${conversionImg}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10'>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Page Views</p>
                                            </div>
                                            <h3 className='title-value'>{TotalPageViews}</h3>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${pageviewImg}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10'>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Registration Limit</p>
                                            </div>
                                            <h3 className='title-value'>{TotalTickets}</h3>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${registerationsImg}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10' style={{ position: "relative" }}>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Payment History</p>
                                            </div>
                                            <h3>&nbsp;</h3>
                                            <p className='text-primary py-3 cursor-pointer' style={{ position: "absolute", bottom: "0" }} onClick={(e) => { navigate('/payment_history/' + EventId, { state: { event_name: EventName } }); }}>View Details</p>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${paymentHistoryImg}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10'>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Remittance Amount</p>
                                            </div>
                                            <h3 className='title-value' style={{fontSize: '25px'}}>₹ {RemittanceAmount}</h3>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${remittanceAmount}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>   

                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10'>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Receivable to Organiser</p>
                                            </div>
                                            <h3 className='title-value' style={{fontSize: '25px'}}>₹ {OrganiserAmount}</h3>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${receivableToOrg}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10'>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Total Payment Gateway</p>
                                            </div>
                                            <h3 className='title-value' style={{fontSize: '25px'}}>₹ {TotalPaymentGateway}</h3>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${paymentGateway}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  

                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-3 mb-5'>
                                <div className='dashboard-card p-5 border-radius-10'>
                                    <div className='row'>
                                        <div className='col-sm-8 d-flex flex-column justify-content-center'>
                                            <div className="d-flex newtooltip-container">
                                                <p className='m-0 pb-3 title'>Total Convenience Fee</p>
                                            </div>
                                            <h3 className='title-value' style={{fontSize: '25px'}}>₹ {TotalConvenience}</h3>
                                        </div>
                                        <div className='col-sm-4'>
                                            <div className='dashboard-sidebox '>
                                                <img className="" src={`${convenienceFee}`} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='row'>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-3">

                                {BarChartData && BarChartData.length > 0 ?
                                    <>
                                        <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                            <div className='row align-items-center mb-3 text-center'>
                                                <div className='col-sm-12'>
                                                    <p className='m-0 py-3 title title-new'>Registration Per Day</p>
                                                </div>
                                            </div>
                                            <BarChart data={BarChartData} />
                                        </div>
                                    </>
                                    :
                                    <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                        <div className='row align-items-center mb-3 text-center'>
                                            <div className='col-sm-12'>
                                                <p className='m-0 py-3 title title-new'>Number of Categories Sold</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img className="w-50" src={`${noRecordFound}`} alt="img" />
                                            <h5 className='title'>No Data Found</h5>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-3">

                                {BookingData && BookingData.length > 0 ?
                                    <>
                                        <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                            <div className='row align-items-center mb-3 text-center'>
                                                <div className='col-sm-12'>
                                                    <p className='m-0 py-3 title title-new'>Number of Categories Sold</p>
                                                </div>
                                            </div>
                                            <DonutChart data={BookingData} />
                                        </div>
                                    </>
                                    :
                                    <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                        <div className='row align-items-center mb-3 text-center'>
                                            <div className='col-sm-12'>
                                                <p className='m-0 py-3 title title-new'>Number of Categories Sold</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img className="w-50" src={`${noRecordFound}`} alt="img" />
                                            <h5 className='title'>No Data Found</h5>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-3">

                                {BookingData && BookingData.length > 0 ?
                                    <>
                                        <div className='dashboard-card border-radius-10' style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                            <div className='row align-items-center mb-3 text-center'>
                                                <div className='col-sm-12'>
                                                    <p className='m-0 py-3 title title-new'>Category Wise Data</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <div class=" p-4  table-container insights-table-container">
                                                        <table className="table table-hover table-bordered">
                                                            <>
                                                                <thead className="thead-dark">
                                                                    <tr>
                                                                        <th scope="col">Category</th>
                                                                        <th scope="col" className='text-center'>Total</th>
                                                                        <th scope="col" className='text-center'>Used</th>
                                                                        <th scope="col" className='text-center'>Pending</th>
                                                                        {/* <th scope="col" className='text-center'>Price</th> */}
                                                                        <th scope="col" className='text-right'>Total Collection</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {BookingData.map((cat, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{cat.TicketName}</td>
                                                                                <td className='text-center'>{cat.total_quantity}</td>
                                                                                <td className='text-center'>{cat.TicketCount}</td>
                                                                                <td className='text-center'>{cat.PendingCount}</td>
                                                                                {/* <td className='text-right'>₹{" "}{parseFloat(cat.SingleTicketPrice).toFixed(2)}</td> */}
                                                                                <td className='text-right'>₹{" "}{parseFloat(cat.TotalTicketPrice).toFixed(2)}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    {/* style={{ borderTop: "1.4px solid", borderColor: "#000", borderBottom: "1.4px solid" }} */}
                                                                    <tr>
                                                                        <td><strong>Total</strong></td>
                                                                        <td className='text-center'><strong>{BookingData.reduce((total, cat) => total + cat.total_quantity, 0)}</strong></td>
                                                                        <td className='text-center'><strong>{BookingData.reduce((total, cat) => total + cat.TicketCount, 0)}</strong></td>
                                                                        <td className='text-center'><strong>{BookingData.reduce((total, cat) => total + cat.PendingCount, 0)}</strong></td>
                                                                        {/* <td className='text-right'>&nbsp;</td> */}
                                                                        <td className='text-right'><strong>₹{" "}{BookingData.reduce((total, cat) => total + cat.TotalTicketPrice, 0).toFixed(2)}</strong></td>
                                                                    </tr>
                                                                </tbody>
                                                            </>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :

                                    <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                        <div className='row align-items-center mb-3 text-center'>
                                            <div className='col-sm-12'>
                                                <p className='m-0 py-3 title title-new'>Category Wise Data</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img className="w-50" src={`${noRecordFound}`} alt="img" />
                                            <h5 className='title'>No Data Found</h5>
                                        </div>
                                    </div>


                                }
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-3">

                                {UtmCode && UtmCode.length > 0 ?
                                    <>
                                        <div className='dashboard-card p-4 border-radius-10' style={{ minHeight: "505px" }}>
                                            <div className='row align-items-center mb-3 text-center'>
                                                <div className='col-sm-12'>
                                                    <p className='m-0 py-3 title title-new'>UTM Campaigns Data</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <div class="table-container insights-table-container">
                                                        <table className="table table-hover table-bordered">
                                                            <>
                                                                <thead className="thead-dark">
                                                                    <tr>
                                                                        <th scope="col">UTM Code</th>
                                                                        <th scope="col" className='text-center'>Total Count</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {UtmCode.map((cat, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{cat.utm_campaign}</td>
                                                                                <td className='text-center'>{cat.total_quantity}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    <tr>
                                                                        <td><strong>Total</strong></td>
                                                                        <td className='text-center'><strong>{UtmCode.reduce((total, cat) => total + cat.total_quantity, 0)}</strong></td>
                                                                    </tr>
                                                                </tbody>
                                                            </>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                    :

                                    <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                        <div className='row align-items-center mb-3 text-center'>
                                            <div className='col-sm-12'>
                                                <p className='m-0 py-3 title title-new'>UTM Campaigns Data</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img className="w-50" src={`${noRecordFound}`} alt="img" />
                                            <h5 className='title'>No Data Found</h5>
                                        </div>
                                    </div>

                                }
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-3">

                                {CouponCodes && CouponCodes.length > 0 ?
                                    <>
                                        <div className='dashboard-card border-radius-10' style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                            <div className='row align-items-center mb-3 text-center'>
                                                <div className='col-sm-12'>
                                                    <p className='m-0 pb-3 title title-new'>Coupons</p>
                                                </div>
                                            </div>
                                           
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <div class="table-container insights-table-container">
                                                        <table className="table table-hover table-bordered">
                                                            <>
                                                                <thead className="thead-dark">
                                                                    <tr>
                                                                        <th scope="col">Code</th>
                                                                        <th scope="col" className='text-center'>Total </th>
                                                                        <th scope="col" className='text-center'>Used </th>
                                                                        <th scope="col" className='text-center'>Available </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {CouponCodes.map((cat, index) => {
                                                                        const difference = cat.TotalDiscountCode - cat.CouponCount;
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{cat.DiscountCode}</td>
                                                                                <td className='text-center'>{cat.TotalDiscountCode}</td>
                                                                                <td className='text-center'>
                                                                                    <p className='cursor-pointer' title='View Details' onClick={(e) => { navigate('/event_netsale/' + EventId + '/' + cat.coupon_id, { state: { event_name: EventName } }); }}>{cat.CouponCount}</p>
                                                                                </td>
                                                                                <td className='text-center'>{difference}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    <tr>
                                                                        <td><strong>Total</strong></td>
                                                                        <td className='text-center'><strong>{CouponCodes.reduce((total, cat) => total + cat.TotalDiscountCode, 0)}</strong></td>
                                                                        <td className='text-center'><strong>{CouponCodes.reduce((total, cat) => total + cat.CouponCount, 0)}</strong></td>
                                                                        <td className='text-center'><strong>{CouponCodes.reduce((total, cat) => total + (cat.TotalDiscountCode - cat.CouponCount), 0)}</strong></td>
                                                                    </tr>
                                                                </tbody>
                                                            </>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :

                                    <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                        <div className='row align-items-center mb-3 text-center'>
                                            <div className='col-sm-12'>
                                                <p className='m-0 py-3 title title-new'>Coupons</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img className="w-50" src={`${noRecordFound}`} alt="img" />
                                            <h5 className='title'>No Data Found</h5>
                                        </div>
                                    </div>

                                }
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-3">

                                {AgeCategory && AgeCategory.length > 0 ?
                                    <>
                                        <div className='dashboard-card border-radius-10' style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                            <div className='row align-items-center mb-3 text-center'>
                                                <div className='col-sm-12'>
                                                    <p className='m-0 pb-3 title title-new'>Age Category</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <div class="table-container insights-table-container">
                                                        <table className="table table-hover table-bordered">
                                                            <>
                                                                <thead className="thead-dark">
                                                                    <tr>
                                                                        <th scope="col">Age Category</th>
                                                                        <th scope="col" className='text-center'>Total Count</th>
                                                                        {/* <th scope="col" className='text-center'>Used Coupons</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {AgeCategory.map((cat, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{cat.age_category}</td>
                                                                                <td className='text-center'>{cat.count}</td>
                                                                                {/* <td className='text-center'>{cat.CouponCount}</td> */}
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                    <tr style={{ borderTop: "2px solid", borderColor: "#000", borderBottom: "2px solid" }}>
                                                                        <td><strong>Total</strong></td>
                                                                        <td className='text-center'><strong>{AgeCategory.reduce((total, cat) => total + cat.count, 0)}</strong></td>
                                                                        {/* <td className='text-center'><strong>{AgeCategory.reduce((total, cat) => total + cat.CouponCount, 0)}</strong></td> */}
                                                                    </tr>
                                                                </tbody>
                                                            </>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :

                                    <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                        <div className='row align-items-center mb-3 text-center'>
                                            <div className='col-sm-12'>
                                                <p className='m-0 py-3 title title-new'>Age Category</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img className="w-50" src={`${noRecordFound}`} alt="img" />
                                            <h5 className='title'>No Data Found</h5>
                                        </div>
                                    </div>

                                }
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-3">

                                {MaleCount > 0 && FemaleCount > 0 ?
                                    <>
                                        <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                            <div className='row align-items-center mb-3 text-center'>
                                                <div className='col-sm-12'>
                                                    <p className='m-0 py-3 title title-new'>Gender Wise</p>
                                                </div>
                                            </div>
                                            <PieChart maleCount={MaleCount} femaleCount={FemaleCount} otherCount={OtherCount} />
                                        </div>
                                    </>
                                    :
                                    <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                        <div className='row align-items-center mb-3 text-center'>
                                            <div className='col-sm-12'>
                                                <p className='m-0 py-3 title title-new'>Gender Wise</p>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img className="w-50" src={`${noRecordFound}`} alt="img" />
                                            <h5 className='title'>No Data Found</h5>
                                        </div>
                                    </div>
                                }
                            </div>
                            
                            {/* created by neha on 10-12-24 */}
                            {RemittanceData && RemittanceData.length > 0 ?
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-3">
                                    <>
                                        <div className='dashboard-card border-radius-10' style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                            <div className='row align-items-center mb-3 text-center'>
                                                <div className='col-sm-12'>
                                                    <p className='m-0 py-3 title title-new'>Remittance Details</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <div class=" p-4  table-container insights-table-container">
                                                        <table className="table table-hover table-bordered">
                                                            <>
                                                                <thead className="thead-dark">
                                                                    <tr>
                                                                        <th class="text-left">Remittance Name</th>
                                                                        <th class="text-left">Remittance Date</th>
                                                                        <th class="text-center">Gross Amount</th>
                                                                        <th class="text-center">SGST</th>
                                                                        <th class="text-center">CGST</th>
                                                                        <th class="text-center">IGST</th>
                                                                        <th class="text-center">Deductions</th>
                                                                        <th class="text-center">TDS</th>
                                                                        <th class="text-right">Amount Remitted</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {RemittanceData.map((remittance, index) => (
                                                                        <tr key={index}>
                                                                            <td className='text-left'>{remittance.remittance_name} </td>
                                                                            <td className='text-left'>{remittance.remittance_date} </td>
                                                                            <td className='text-center'>{ remittance.gross_amount }</td>
                                                                            <td className='text-center'>{ remittance.Sgst }</td>
                                                                            <td className='text-center'>{ remittance.Cgst } </td>
                                                                            <td className='text-center'>{ remittance.Igst } </td>
                                                                            <td className='text-center'>{ remittance.deductions  }</td>
                                                                            <td className='text-center'>{ remittance.Tds } </td>
                                                                            <td className='text-right'>{ remittance.amount_remitted }</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            : null }

                            {/* created by neha on 10-12-24 */}
                            {MarketingData && MarketingData.length > 0 ?
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-3">
                                    <>
                                        <div className='dashboard-card border-radius-10' style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                            <div className='row align-items-center mb-3 text-center'>
                                                <div className='col-sm-12'>
                                                    <p className='m-0 py-3 title title-new'>Marketing Details</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12'>
                                                    <div class=" p-4  table-container insights-table-container">
                                                        <table className="table table-hover table-bordered">
                                                            <>
                                                                <thead className="thead-dark">
                                                                    <tr>
                                                                    <th class="text-left">Campaign Name</th>
                                                                    <th class="text-left">Campaign Type</th>
                                                                    <th class="text-left">Start Date</th>
                                                                    <th class="text-left">End Date</th>
                                                                    <th class="text-center">Count</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {MarketingData.map((marketing, index) => (
                                                                        <tr key={index}>
                                                                            <td className='text-left'>{marketing.campaign_name} </td>
                                                                            <td className='text-left'>{marketing.campaign_type} </td>
                                                                            <td className='text-left'>{ marketing.start_date }</td>
                                                                            <td className='text-left'>{ marketing.end_date } </td>
                                                                            <td className='text-center'>{ marketing.count }</td>
                                                                           
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    {/* // :

                                    // <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                    //     <div className='row align-items-center mb-3 text-center'>
                                    //         <div className='col-sm-12'>
                                    //             <p className='m-0 py-3 title title-new'>Marketing Details</p>
                                    //         </div>
                                    //     </div>
                                    //     <div className='d-flex flex-column align-items-center'>
                                    //         <img className="w-50" src={`${noRecordFound}`} alt="img" />
                                    //         <h5 className='title'>No Data Found</h5>
                                    //     </div>
                                    // </div> */}
                                </div>
                            : null }


                            {Object.keys(CustomQArray).map((key, idx) => {
                                const CustomQArray1 = Object.keys(CustomQArray[key]).filter(k => k !== 'question_label').map(k => CustomQArray[key][k]);
                                return (
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-3" key={idx}>
                                         {/* {console.log(key)} */}
                                        {CustomQArray1 && CustomQArray1.length > 0 ? (
                                            <div className='dashboard-card border-radius-10' style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                                <div className='row align-items-center mb-3 text-center'>
                                                    <div className='col-sm-12'>
                                                        <p className='m-0 p-3 title-new text-left'>{CustomQArray[key].question_label}</p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-sm-12'>
                                                        <div class="p-4 table-container insights-table-container">
                                                            <table className="table table-hover table-bordered">
                                                                <thead className="thead-dark">
                                                                    <tr>
                                                                        <th scope="col" className='text-left'>Label</th>
                                                                        <th scope="col" className='text-center'>Count</th>
                                                                        {/* {CustomQArray[key].limit_flag ?
                                                                                <> */}
                                                                                <th scope="col" className='text-center'>Limit</th>
                                                                                {/* </> : null
                                                                        } */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                   
                                                                    {CustomQArray1.map((cat, index) => (
                                                                       <>
                                                                           {cat !== true ?
                                                                            <tr key={index}>
                                                                                <td>{cat.label}</td>
                                                                                <td className='text-center'>{cat.count}</td>
                                                                                {/* {CustomQArray[key].limit_flag ?
                                                                                        <> */}
                                                                                        <td className='text-center'>{cat.limit ? cat.limit : 0}</td>
                                                                                        {/* </> : null
                                                                                } */}
                                                                            </tr>
                                                                           : '' }
                                                                       </>
                                                                       
                                                                    ))}

                                                                    <tr>
                                                                        <td><strong>Total</strong></td>
                                                                        <td className='text-center'><strong>
                                                                         {CustomQArray1.reduce((sum, cat) => sum + cat.count, 0)}
                                                                        </strong></td>
                                                                        <td className='text-center'><strong>
                                                                          {CustomQArray1.reduce((sum, cat) => sum + cat.limit, 0)}</strong>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (

                                            <div className="dashboard-card border-radius-10" style={{ minHeight: "505px", display: "flex", flexDirection: "column" }}>
                                                <div className='row align-items-center mb-3 text-center'>
                                                    <div className='col-sm-12'>
                                                        <p className='m-0 py-3 title title-new'>{CustomQArray[key].question_label}</p>
                                                    </div>
                                                </div>
                                                <div className='d-flex flex-column align-items-center'>
                                                    <img className="w-50" src={`${noRecordFound}`} alt="img" />
                                                    <h5 className='title'>No Data Found</h5>
                                                </div>
                                            </div>



                                        )}
                                    </div>
                                );
                            })}

                        </div>
                        
                    </div>
                </div>
            </main >
        </>

    )
}

export default EventDashbaord