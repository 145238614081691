import React, { useState, useEffect } from 'react';
import axios from '../axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderImg from '../img/logo/running.gif';
import AddGeneralQuestionsForm from "../Create_event_details/AddGeneralQuestionsForm";

const FormQuestions = (props) => {

  const [FormQuestionAll, setFormQuestionAll] = useState([]);
  const [FormQuestionDetailsAll, setFormQuestionDetailsAll] = useState([]);
  const [AddGeneralQuestions, setAddGeneralQuestions] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [EventFlag, setEventFlag] = useState(false);
  //console.log(props.FieldMappingDetails);
  const [DeleteConfirmPopup, setDeleteConfirmPopup] = useState(false);
  const [GeneralFormId, setGeneralFormId] = useState('');
  const [isDelete, setisDelete] = useState(false);

  const [SortOrder, setSortOrder] = useState(false);
  const [sorting_order_flag, setsorting_order_flag] = useState(false);

  const [DragStartFormId, setDragStartFormId] = useState('');
  const [DragOverFormId, setDragOverFormId] = useState('');

  const [DragStartGeneralForm, setDragStartGeneralForm] = useState(0);
  const [DragOverGeneralForm, setDragOverGeneralForm] = useState(0);

  const [keysToCheck, setkeysToCheck] = useState([]);
  const [DragDropFlag, setDragDropFlag] = useState(false);
  const [IsVideo, setIsVideo] = useState(false);
  const [QuestionFormName, setQuestionFormName] = useState('');
  
  const [TicketConfirmPopup, setTicketConfirmPopup] = useState(false);
  const [ShowRemoveTicketPdf, setShowRemoveTicketPdf] = useState(0);
  // console.log(ShowRemoveTicketPdf);
  const [SubQuestionFlag, setSubQuestionFlag] = useState(0);

  const [CommId, setCommId] = useState('');
  const [EventStatus, setEventStatus] = useState(false);
  const [ActionFlag, setActionFlag] = useState('');
  const [evntStatus, setevntStatus] = useState(''); 
  const [eventIndex, seteventIndex] = useState(''); 
  const [FormKey, setFormKey] = useState(''); 
  const [ChangeStatusPopup, setChangeStatusPopup] = useState(false);
 
  const common_class = 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-5 form-que';

  const handleComm = (comm_id, operate) => {
    if (operate === "change_status") {
       setChangeStatusPopup(true);
    }
  }

  const ActionChangeStatus = () => {
        // console.log(FormQuestionAll,evntStatus);
      if(ActionFlag === 'change_status'){
          setEventFlag(true);  
          let EventArray = [...FormQuestionAll[FormKey]];
          EventArray[eventIndex]['question_status'] = !evntStatus;
          setFormQuestionAll([FormKey].EventArray);
      }
      // console.log(FormQuestionAll,EventStatus);
      setLoader(true);
      const payload = {
          coupon_id: CommId,
          event_form_status: evntStatus,
          action_flag: 'event_form_changes_status'
      };
      // console.log(payload);
      axios.post("status_coupon",payload)
      .then((response) => {
      // console.log(response);
          if (response.status === 200) {
              setLoader(false);
              toast.success(response.data.message)
              setevntStatus(''); 
              seteventIndex('');
              setFormKey('');
              props.setEventApiCall(true);
              setEventStatus(false)
          }
      })
      .catch((error) => {
          setLoader(false);
          console.log(error);
      })
  }
 
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(true);
    const payload = {
      event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
    };
    axios.post("eventFormQuestions", payload)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          setLoader(false);
          setFormQuestionAll(response.data.data.form_question !== '' ? response.data.data.form_question.event_form_details : []);
          setFormQuestionDetailsAll(response.data.data.form_question.event_form_array);
          setEventFlag(false);
          setsorting_order_flag(false);
          setDragDropFlag(false);
          setDragOverGeneralForm(0);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      })

  }, [AddGeneralQuestions, EventFlag, sorting_order_flag, DragDropFlag])
  //console.log(FormQuestionAll);

  const DeleteEventQuestions = (GeneralFormId) => {

    //if(isDelete && isDelete!== ''){
    setLoader(true);
    const payload = {
      event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
      general_form_id: GeneralFormId,
      question_form_name: QuestionFormName
    };
    //console.log(payload);
    axios.post("deleteEventFormQuestions", payload)
      .then((response) => {
        setLoader(false);
        // console.log(response);
        if (response.status === 200) {
          props.setEventApiCall(true);
          setEventFlag(true);
          toast.success(response.data.message);
        }
      })
      .catch(() => {
        setLoader(false);
        console.log('error');
      });
    //}
  }
  //console.log(QuestionFormName);

  //---------- new added
  const RemoveAddTikcetPdf = (GeneralFormId) => {
    // console.log(GeneralFormId);
    setLoader(true);
    const payload = {
      event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
      general_form_id: GeneralFormId,
      ticket_pdf_show_flag : ShowRemoveTicketPdf === 0 ? 1 : 0,
      child_question_ids : SubQuestionFlag
    };
    // console.log(payload);
    axios.post("removeAddQuestionTicketPdf", payload)
      .then((response) => {
        setLoader(false);
        // console.log(response);
        if (response.status === 200) {
          props.setEventApiCall(true);
          setEventFlag(true);
          setShowRemoveTicketPdf(0); setSubQuestionFlag(0);
          toast.success(response.data.message);
        }
      })
      .catch(() => {
        setLoader(false);
        console.log('error');
      });
    
  }

 //console.log(FormQuestionAll['Personal Information']);
  const handleDragStart = (e, index, form_id, general_id) => {
    e.dataTransfer.setData('index', index);
    setDragStartFormId(form_id);
    setDragStartGeneralForm(general_id);
  };
   
  const handleDragOver = (e,form_id, general_id) => {
  //  console.log(form_id);
    setDragOverFormId(form_id);
    setDragOverGeneralForm(general_id);
    e.preventDefault();
  
  }; 
   
  const handleDrop = (e, newIndex) => {
    const draggedIndex = e.dataTransfer.getData('index');
    const newList = [...FormQuestionDetailsAll];
    const [removedItem] = newList.splice(draggedIndex, 1);
    newList.splice(newIndex, 0, removedItem);

   // console.log(newList);
    setFormQuestionDetailsAll(newList);
    
    if(DragStartGeneralForm !== DragOverGeneralForm){
      if(DragStartFormId !== DragOverFormId){
        toast.error('Sorting is possible only within form');
      }else{
        setSortOrder(true);
      }
    }
    setDragDropFlag(true);
    
  };

  //--------------------

  // const handleDragStart1 = (e, index, category) => {
  //   console.log(index);
  //   e.dataTransfer.setData('index', index);
  //   e.dataTransfer.setData('category', category);
  // };

  // const handleDragOver1 = (e) => {
  //   e.preventDefault();
  // };

  // const handleDrop1 = (e, newIndex, newCategory) => {
  //   const draggedIndex = e.dataTransfer.getData('index');
  //   const draggedCategory = e.dataTransfer.getData('category');

  //   const item = FormQuestionAll[draggedCategory][draggedIndex];
  //   const newList = [...FormQuestionAll[draggedCategory]];
  //   // newList.splice(draggedIndex, 1);
  //   // const newData = { ...FormQuestionAll, [draggedCategory]: newList };

  //   // const targetList = [...FormQuestionAll[newCategory]];
  //   // targetList.splice(newIndex, 0, item);
  //   // const finalData = { ...newData, [newCategory]: targetList };

  //   const [removedItem] = newList.splice(draggedIndex, 1);
  //   newList.splice(newIndex, 0, removedItem);
    
  //   setFormQuestionAll(newList);
  // };
  
  // console.log(props.TicketsDetails);

  useEffect(() => {
    if (SortOrder === true) {
      const payload = {
        event_id: localStorage.getItem("EventId") ? localStorage.getItem("EventId") : 0,
        event_form_question_array: FormQuestionDetailsAll
      };
      // console.log(payload);
      axios.post("EventFormQuestionsSorting", payload)
        .then((response) => {
          //console.log(response);
          if (response.status === 200) {
            props.setEventApiCall(true);
            setSortOrder(false);
            setsorting_order_flag(true);
            setDragStartFormId(0);
            toast.success(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
      // setSortOrder(false);
    }
   
  }, [SortOrder])
  // console.log(FormQuestionAll);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setEventResponceTabs(6);
  }
  
  useEffect(() => {
    const payload = {
      form_name: '',
      form_edit_id: 0,
      form_action_flag: 'form_details',
      form_flag: 'general_form'
    };
    axios.post("FormCommonDetails", payload)
      .then((response) => {
        //console.log(response);
        if (response.status === 200) {
          setkeysToCheck(response.data.data.form_array);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }, [])

  //const keysToCheck = ["Personal Information", "Address Details", "Medical Information", "Documentation", "Emergency Contact", "Additional Information", "Professional Information", "Social Media Links", "Other Details"];

  const commonSvgPath = "M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z";

  const deleteSvgPath = "M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z";

  return (
    <>
      {
          ChangeStatusPopup && (

              <div className="modal is-visible" data-animation="slideInUp">
                  <div className="modal-dialog quickview__main--wrapper">
                      <div className="quickview__inner">
                          <div className="row">
                              <div className="login__section">
                                  <div className="login__section--inner">
                                      <div className="p-5">
                                          <div className="account__login--header mb-25">
                                              
                                          <p className="account__login--header__desc">Are you sure you want to change status this record?</p>
                                          </div>
                                          <div className="d-flex gap-4">
                                              <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                                                  setChangeStatusPopup(false);
                                              }}>Cancel</button>
                                             
                                              <button className="account__login--btn primary__btn mt-2" onClick={(e) => { setChangeStatusPopup(true); ActionChangeStatus(); setChangeStatusPopup(false);  }}>Yes</button>
                                             
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          )
      }

      {
        DeleteConfirmPopup && (

          <div className="modal is-visible" data-animation="slideInUp">
            <div className="modal-dialog quickview__main--wrapper">
              <div className="quickview__inner">
                <div className="row">
                  <div className="login__section">
                    <div className="login__section--inner">
                      <div className="p-5">
                        <div className="account__login--header mb-25">
                          
                            {QuestionFormName == 'country' ?
                              <p className="account__login--header__desc">
                              State & City questions will also be removed, if you delete this question.<br />
                              Are you sure you want to delete this record?</p>
                            : QuestionFormName == 'state' ?
                              <p className="account__login--header__desc">
                              City questions will also be removed, if you delete this question.<br />
                              Are you sure you want to delete this record?</p>
                            :
                             <p className="account__login--header__desc">
                              All sub-questions will also get deleted if main question is deleted.<br />
                              Are you sure you want to delete this record?</p>
                            }
                        </div>
                        <div className="d-flex gap-4">
                          <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                            setDeleteConfirmPopup(false);
                          }}>Cancel</button>

                          <button className="account__login--btn primary__btn mt-2" onClick={(e) => { DeleteEventQuestions(GeneralFormId); setisDelete(true); setDeleteConfirmPopup(false); }}>Yes</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {
        TicketConfirmPopup && (

          <div className="modal is-visible" data-animation="slideInUp">
            <div className="modal-dialog quickview__main--wrapper">
              <div className="quickview__inner">
                <div className="row">
                  <div className="login__section">
                    <div className="login__section--inner">
                      <div className="p-5">
                        <div className="account__login--header mb-25">
                          
                            <p className="account__login--header__desc">Are you sure you want to hide/show this question on registration pdf ?</p>
                           
                        </div>
                        <div className="d-flex gap-4">
                          <button className="account__login--btn secondary__btn mt-2" onClick={() => {
                            setTicketConfirmPopup(false) }}>Cancel</button>

                          <button className="account__login--btn primary__btn mt-2" onClick={(e) => { RemoveAddTikcetPdf(GeneralFormId); setTicketConfirmPopup(false); }}>Yes</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {/* open video for event form question */}
      {
        IsVideo ?

          <div className="modal is-visible" data-animation="slideInUp">
            <div className="modal-dialog quickview__main--wrapper" style={{width: "100%"}}>
              <header className="modal-header quickview__header">
                <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => {
              setIsVideo(false);
            }}>✕ </button>
              </header>
              <div className="quickview__inner">
              <iframe style={{width: "100%", height: "500px"}} src="https://www.youtube.com/embed/Md8ZHCliZkY?si=W_E0afyAPubfxuNO&amp;autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          :
        null
      }

      <div>

        {/* <ToastContainer theme="colored" /> */}
        {Loader ? <div><div id='loader' style={{ backgroundImage: `url(${LoaderImg})` }}></div></div> : null}

        {AddGeneralQuestions ?
          <AddGeneralQuestionsForm setAddGeneralQuestions={setAddGeneralQuestions} setEventResponceTabs={props.setEventResponceTabs} FieldMappingDetails={props.FieldMappingDetails} TicketsDetails={props.TicketsDetails}/>
          :
          <form method="post" onSubmit={handleSubmit}>

            <div className="main checkout__mian">

              <div className="section__header mb-4 d-flex align-items-center justify-content-between">
               
                <div className='d-flex gap-3'>
                <h2 className="section__header--title h3">Event Form Questions</h2>
                <div className='tooltip'>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="m380-300 280-180-280-180v360ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" onClick={(e)=>{setIsVideo(true);}} /></svg>  
                  <span className="tooltiptext position-fixed w-auto">View Titorial</span>
                </div>
                </div>
                
                

                <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setAddGeneralQuestions(true); }} style={{ cursor: 'pointer' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg> Add Questions
                </div>
              </div>
              
              <p className='my-4' style={{color: 'gray'}}>You can sort order for the following question. Just Drag and Drop. </p>

              <div className="row">

                {/* ------------------------------------ */}
             {/* {console.log(FormQuestionAll)} */}
                {FormQuestionAll ? Object.entries(FormQuestionAll).map(([key, formArry]) => (
                  // <div className="row bg-light my-3 py-3 border border-dotted border-2 border-primary " key={key}>
                  <div className="row my-2" key={key}>
                    {keysToCheck.includes(key) ? <div><h4 className="mb-3 ">{key === 'Custom Form' ? '' : key}:</h4></div> : null}
                    
                    
                    {Object.values(formArry).map((item, index) =>
                      // form wise rendering code
                      item.question_form_type === "radio" && item.child_question_ids === '' ? (  // radio button 

                        <div className={DragOverGeneralForm === item.general_form_id && DragStartGeneralForm !== item.general_form_id ? common_class+' drop-ui' : common_class+' drag-ui' }
                          style={item.is_subquestion === 0 ? { cursor: 'move' } : {}}
                          key={item.id}
                          draggable={item.is_subquestion === 0 ? true : false}
                          onDragStart={(e) => handleDragStart(e, item.common_index, item.new_form_name, item.general_form_id)}
                          onDragOver={(e) => handleDragOver(e, item.new_form_name, item.general_form_id)} 
                          onDrop={(e) => handleDrop(e, item.common_index)}
                        >
                          <div className="card w-100">
                            <div className="mx-3 drag-icon">
                              {item.is_subquestion === 0 ?
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={commonSvgPath} /></svg>
                                : ''}
                            </div>
                            <div className="mx-3 w-100 input-field">
                              <div className="row">
                                <div className="col-md-10" style={{ textAlign: 'left' }}>{item.question_label} {item.is_manadatory === 1 ? <span className="checkout__input--label__star">(Mandatory)</span> : ''} </div>
                                {item.question_form_option.map((item1) => {
                                  return (
                                    <div className="col-12 col-sm-6 col-md-12 col-lg-4 col-xl-4 col-xxl-4 my-2 plans">
                                      <label className="plan basic-plan">
                                        <input name="event_type"
                                          type="radio"
                                          className="btn-check"
                                          disabled
                                        />
                                        <div className="plan-content">
                                          <img loading="lazy" src="https://raw.githubusercontent.com/ismailvtl/ismailvtl.github.io/master/images/life-saver-img.svg" alt="" />
                                          <div className="plan-details">
                                            <span className="h3 m-0">{item1.label}</span>
                                          </div>
                                        </div>
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>

                            <div className="mx-3 option-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setDeleteConfirmPopup(true); setGeneralFormId(item.general_form_id); }} ><path d={deleteSvgPath} /></svg>
                            </div>

                              {/* ---------- quetion show/hide on ticket pdf */}
                              <div className="mx-3 option-icon-edit" style={{width: "35px", height: "35px"}} title='Show on  pdf'>
                                  <input type="checkbox" id="show_on_ticket_pdf" name="show_on_ticket_pdf" defaultValue={item.show_on_ticket_pdf} defaultChecked={item.show_on_ticket_pdf === 1 ? true : false} 
                                  onClick={() => { setTicketConfirmPopup(true); setGeneralFormId(item.general_form_id); setShowRemoveTicketPdf(item.show_on_ticket_pdf); setSubQuestionFlag(item.child_question_ids); }}/>
                              </div>

                              <div className="mx-3 option-icon-status" title='Question Status'>
                                <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                      <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                      value={item.question_status}
                                      checked={item.question_status}
                                      onChange={(e) => setEventStatus(!EventStatus)} 
                                      onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status'); setevntStatus(item.question_status); seteventIndex(index); setFormKey(key);  }} />
                                      
                                  </div> 
                              </div>

                          </div>
                        </div>

                      )

                        : item.question_form_type === 'select' && item.is_subquestion === 0 ? (  // select dropdown

                          <div className={DragOverGeneralForm === item.general_form_id && DragStartGeneralForm !== item.general_form_id ? common_class+' drop-ui' : common_class+' drag-ui' }
                            style={item.is_subquestion === 0 ? { cursor: 'move' } : {}}
                            key={item.id}
                            draggable={item.is_subquestion === 0 ? true : false}
                            onDragStart={(e) => handleDragStart(e, item.common_index, item.new_form_name, item.general_form_id)}
                            onDragOver={(e) => handleDragOver(e, item.new_form_name, item.general_form_id)} 
                            onDrop={(e) => handleDrop(e, item.common_index)}
                          >

                            <div className="card w-100">
                              <div className="mx-3 drag-icon">
                                {item.is_subquestion === 0 ?
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={commonSvgPath} /></svg>
                                  : ''}
                              </div>

                              <div className="w-100">
                                <div className="mx-3 w-100 input-field">
                                  <div className="row">

                                    <div className="mx-3 w-100 input-field text-left">
                                      <label>{item.question_label} {item.is_manadatory === 1 ? <span className="checkout__input--label__star">(Mandatory)</span> : ''} </label>
                                      <div className="form-floating mt-3 mb-0">
                                        <input className="predictive__search--input" placeholder={"Select " + item.question_label} type="text" disabled />
                                      </div>
                                    </div>

                                    {/* -------- sub-question -------- */}
                                    {item.sub_questions_array ?
                                      item.sub_questions_array.map((item1, index1) =>
                                      (
                                        <div key={index1}>
                                          <div className="mx-3 w-100 input-field">
                                            <div className="form-floating mt-3 mb-0">
                                              <input
                                                type={item1.question_form_type}
                                                className="form-control"
                                                placeholder=""
                                                disabled
                                              />
                                              <label>{item1.question_label} {item1.is_manadatory === 1 ? <span className="checkout__input--label__star">(Mandatory)</span> : ''} </label>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    ) : ''}

                                  </div>
                                </div>

                                <div className="mx-3 option-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setDeleteConfirmPopup(true); setGeneralFormId(item.general_form_id); }} ><path d={deleteSvgPath} /></svg>
                                </div>

                                {/* ---------- quetion show/hide on ticket pdf */}
                                <div className="mx-3 option-icon-edit" style={{width: "35px", height: "35px"}} title='Show on pdf'>
                                    <input type="checkbox" id="show_on_ticket_pdf" name="show_on_ticket_pdf" defaultValue={item.show_on_ticket_pdf} defaultChecked={item.show_on_ticket_pdf === 1 ? true : false} 
                                    onClick={() => { setTicketConfirmPopup(true); setGeneralFormId(item.general_form_id); setShowRemoveTicketPdf(item.show_on_ticket_pdf); setSubQuestionFlag(item.child_question_ids); }}/>
                                </div>

                                <div className="mx-3 option-icon-status" title='Question Status'>
                                  <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                        <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                        value={item.question_status}
                                        checked={item.question_status}
                                        onChange={(e) => setEventStatus(!EventStatus)} 
                                        onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status'); setevntStatus(item.question_status); seteventIndex(index); setFormKey(key);  }} />
                                    </div> 
                                </div>

                              </div>
                            </div>
                          </div>

                        ) : item.question_form_type === 'file' ? (   // select file

                          <div className={DragOverGeneralForm === item.general_form_id && DragStartGeneralForm !== item.general_form_id ? common_class+' drop-ui' : common_class+' drag-ui' }
                            style={item.is_subquestion === 0 ? { cursor: 'move' } : {}}
                            key={item.id}
                            draggable={item.is_subquestion === 0 ? true : false}
                            onDragStart={(e) => handleDragStart(e, item.common_index, item.new_form_name, item.general_form_id)}
                            onDragOver={(e) => handleDragOver(e, item.new_form_name, item.general_form_id)} 
                            onDrop={(e) => handleDrop(e, item.common_index)}
                          >

                            <div className="card w-100">
                              <div className="mx-3 drag-icon">
                                {item.is_subquestion === 0 ?
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={commonSvgPath} /></svg>
                                  : ''}
                              </div>
                              <div className="mx-3 w-100 input-field">
                                <div className="form-floating mt-3 mb-0">
                                  <div className="drag-drop-img">
                                    <p className="h4">{item.question_label} {item.is_manadatory === 1 ? <span className="checkout__input--label__star">(Mandatory)</span> : ''} </p>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50">
                                      <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm40-80h480L570-480 450-320l-90-120-120 160Zm-40 80v-560 560Z" />
                                    </svg>
                                    <p className="h4">Click or Drag and Drop</p>
                                    <small>JPEGs or PNGs smaller than 10 MB allowed</small>
                                    <input
                                      type="file"
                                      style={{ display: 'none' }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="mx-3 option-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setDeleteConfirmPopup(true); setGeneralFormId(item.general_form_id); }} ><path d={deleteSvgPath} /></svg>
                              </div>

                              {/* ---------- quetion show/hide on ticket pdf */}
                              <div className="mx-3 option-icon-edit" style={{width: "35px", height: "35px"}} title='Show on pdf'>
                                  <input type="checkbox" id="show_on_ticket_pdf" name="show_on_ticket_pdf" defaultValue={item.show_on_ticket_pdf} defaultChecked={item.show_on_ticket_pdf === 1 ? true : false} 
                                  onClick={() => { setTicketConfirmPopup(true); setGeneralFormId(item.general_form_id); setShowRemoveTicketPdf(item.show_on_ticket_pdf); setSubQuestionFlag(item.child_question_ids); }}/>
                              </div>

                              <div className="mx-3 option-icon-status" title='Question Status'>
                                <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                      <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                      value={item.question_status}
                                      checked={item.question_status}
                                      onChange={(e) => setEventStatus(!EventStatus)} 
                                      onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status'); setevntStatus(item.question_status); seteventIndex(index); setFormKey(key);  }} />
                                      
                                  </div> 
                              </div>

                            </div>
                          </div>

                        ) : item.question_form_type === 'textarea' ? (  // input type [textarea] 

                          <div className={DragOverGeneralForm === item.general_form_id && DragStartGeneralForm !== item.general_form_id ? common_class+' drop-ui' : common_class+' drag-ui' }
                            style={item.is_subquestion === 0 ? { cursor: 'move' } : {}}
                            key={item.id}
                            draggable={item.is_subquestion === 0 ? true : false}
                            onDragStart={(e) => handleDragStart(e, item.common_index, item.new_form_name, item.general_form_id)}
                            onDragOver={(e) => handleDragOver(e, item.new_form_name, item.general_form_id)} 
                            onDrop={(e) => handleDrop(e, item.common_index)}
                          >

                            <div className="card w-100">
                              <div className="mx-3 drag-icon">
                                {item.is_subquestion === 0 ?
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={commonSvgPath} /></svg>
                                  : ''}
                              </div>
                              <div className="mx-3 w-100 input-field">
                                <div className="form-floating mt-3 mb-0">
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    style={{ minHeight: "100px", display: "inline-block", resize: "both" }}
                                    value=""
                                    disabled
                                  />
                                  <label>{item.question_label} {item.is_manadatory === 1 ? <span className="checkout__input--label__star">(Mandatory)</span> : ''} </label>
                                </div>
                              </div>


                              <div className="mx-3 option-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setDeleteConfirmPopup(true); setGeneralFormId(item.general_form_id); }} ><path d={deleteSvgPath} /></svg>
                              </div>

                              {/* ---------- quetion show/hide on ticket pdf */}
                              <div className="mx-3 option-icon-edit" style={{width: "35px", height: "35px"}} title='Show on  pdf'>
                                  <input type="checkbox" id="show_on_ticket_pdf" name="show_on_ticket_pdf" defaultValue={item.show_on_ticket_pdf} defaultChecked={item.show_on_ticket_pdf === 1 ? true : false} 
                                  onClick={() => { setTicketConfirmPopup(true); setGeneralFormId(item.general_form_id); setShowRemoveTicketPdf(item.show_on_ticket_pdf); setSubQuestionFlag(item.child_question_ids); }}/>
                              </div>

                              <div className="mx-3 option-icon-status" title='Question Status'>
                                <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                      <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                      value={item.question_status}
                                      checked={item.question_status}
                                      onChange={(e) => setEventStatus(!EventStatus)} 
                                      onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status'); setevntStatus(item.question_status); seteventIndex(index); setFormKey(key);  }} />
                                      
                                  </div> 
                              </div>

                            </div>
                          </div>

                        ) : item.question_form_type === 'checkbox' ? (   // input type [checkbox] 

                          <div className={DragOverGeneralForm === item.general_form_id && DragStartGeneralForm !== item.general_form_id ? common_class+' drop-ui' : common_class+' drag-ui' }
                            style={item.is_subquestion === 0 ? { cursor: 'move' } : {}}
                            key={item.id}
                            draggable={item.is_subquestion === 0 ? true : false}
                            onDragStart={(e) => handleDragStart(e, item.common_index, item.new_form_name, item.general_form_id)}
                            onDragOver={(e) => handleDragOver(e, item.new_form_name, item.general_form_id)} 
                            onDrop={(e) => handleDrop(e, item.common_index)}
                          >

                            <div className="card w-100">
                              <div className="mx-3 drag-icon">
                                {item.is_subquestion === 0 ?
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={commonSvgPath} /></svg>
                                  : ''}
                              </div>
                              <div className="mx-3 w-100 input-field">
                                <div className="row">
                                  <div className="col-md-12" style={{ textAlign: 'left' }}>{item.question_label} {item.is_manadatory === 1 ? <span className="checkout__input--label__star">(Mandatory)</span> : ''}</div>
                                  {item.question_form_option.map((item1) => {
                                    return (
                                      <div className=" col-12 col-sm-6 col-md-12 col-lg-4 col-xl-4 col-xxl-4 my-2 plans">
                                        <label className="plan basic-plan">
                                          <input name="event_type"
                                            type="checkbox"
                                            className="btn-check"
                                            disabled
                                          />
                                          <div className="plan-content">
                                            <img loading="lazy" src="https://raw.githubusercontent.com/ismailvtl/ismailvtl.github.io/master/images/life-saver-img.svg" alt="" />
                                            <div className="plan-details">
                                              <span className="h3 m-0">{item1.label}</span>
                                            </div>
                                          </div>
                                        </label>
                                      </div>
                                    )
                                  })}

                                </div>
                              </div>

                              <div className="mx-3 option-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setDeleteConfirmPopup(true); setGeneralFormId(item.general_form_id); }} ><path d={deleteSvgPath} /></svg>
                              </div>

                              {/* ---------- quetion show/hide on ticket pdf */}
                              <div className="mx-3 option-icon-edit" style={{width: "35px", height: "35px"}} title='Show on pdf'>
                                  <input type="checkbox" id="show_on_ticket_pdf" name="show_on_ticket_pdf" defaultValue={item.show_on_ticket_pdf} defaultChecked={item.show_on_ticket_pdf === 1 ? true : false} 
                                  onClick={() => { setTicketConfirmPopup(true); setGeneralFormId(item.general_form_id); setShowRemoveTicketPdf(item.show_on_ticket_pdf); setSubQuestionFlag(item.child_question_ids); }}/>
                              </div>

                              <div className="mx-3 option-icon-status" title='Question Status'>
                                <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                      <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                      value={item.question_status}
                                      checked={item.question_status}
                                      onChange={(e) => setEventStatus(!EventStatus)} 
                                      onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status'); setevntStatus(item.question_status); seteventIndex(index); setFormKey(key);  }} />
                                      
                                  </div> 
                              </div>

                            </div>
                          </div>

                          // input type [text | email | date | time]   
                        ) : item.question_form_type === 'text' || item.question_form_type === 'date' || item.question_form_type === 'time' || item.question_form_type === 'email' || item.question_form_type === 'amount' || item.question_form_type === 'countries' || item.question_form_type === 'states' || item.question_form_type === 'cities' || item.question_form_type === 'mobile' ? (

                          <div className={DragOverGeneralForm === item.general_form_id && DragStartGeneralForm !== item.general_form_id ? common_class+' drop-ui' : common_class+' drag-ui' }
                         
                            style={item.is_subquestion === 0 ? { cursor: 'move' } : {}}
                            key={item.id}
                            draggable={item.is_subquestion === 0 ? true : false}
                            onDragStart={(e) => handleDragStart(e, item.common_index, item.new_form_name, item.general_form_id)}
                            onDragOver={(e) => handleDragOver(e, item.new_form_name, item.general_form_id)} 
                            onDrop={(e) => handleDrop(e, item.common_index)}
                            // onDragStart={(e) => handleDragStart1(e, index, key)}
                            // onDragOver={(e) => handleDragOver1(e)}
                            // onDrop={(e) => handleDrop1(e, index, key)}
                          >

                            <div className="card w-100">
                              <div className="mx-3 drag-icon">
                                {item.is_subquestion === 0 ?
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={commonSvgPath} /></svg>
                                  : ''}
                              </div>
                              <div className="mx-3 w-100 input-field">
                                <div className="form-floating mt-3 mb-0">
                                  <input
                                    type={item.question_form_type}
                                    className="form-control"
                                    placeholder=""
                                    disabled
                                  />
                                  <label>{item.question_label} {item.is_manadatory === 1 ? <span className="checkout__input--label__star">(Mandatory)</span> : ''} </label>
                                </div>
                              </div>

                              <div className="mx-3 option-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setDeleteConfirmPopup(true); setGeneralFormId(item.general_form_id); setQuestionFormName(item.question_form_name); }} ><path d={deleteSvgPath} /></svg> 
                              </div>

                              {/* ---------- quetion show/hide on ticket pdf */}
                              <div className="mx-3 option-icon-edit" style={{width: "35px", height: "35px"}} title='Show on pdf'>
                                  <input type="checkbox" id="show_on_ticket_pdf" name="show_on_ticket_pdf" defaultValue={item.show_on_ticket_pdf} defaultChecked={item.show_on_ticket_pdf === 1 ? true : false} 
                                  onClick={() => { setTicketConfirmPopup(true); setGeneralFormId(item.general_form_id); setShowRemoveTicketPdf(item.show_on_ticket_pdf); setSubQuestionFlag(item.child_question_ids); }}/>
                              </div>

                              <div className="mx-3 option-icon-status" title='Question Status'>
                                <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                      <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                      value={item.question_status}
                                      checked={item.question_status}
                                      onChange={(e) => setEventStatus(!EventStatus)} 
                                      onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status'); setevntStatus(item.question_status); seteventIndex(index); setFormKey(key);  }} />
                                      
                                  </div> 
                              </div>

                            </div>
                          </div>

                        ) : item.child_question_ids !== '' ?

                          (
                            <>
                              <div className={DragOverGeneralForm === item.general_form_id && DragStartGeneralForm !== item.general_form_id ? common_class+' drop-ui' : common_class+' drag-ui' }
                                style={item.is_subquestion === 0 ? { cursor: 'move' } : {}}
                                key={item.id}
                                draggable={item.is_subquestion === 0 ? true : false}
                                onDragStart={(e) => handleDragStart(e, item.common_index, item.new_form_name, item.general_form_id)}
                                onDragOver={(e) => handleDragOver(e, item.new_form_name, item.general_form_id)} 
                                onDrop={(e) => handleDrop(e, item.common_index)}
                              >
                                <div className="card w-100">

                                  <div className="mx-3 drag-icon">
                                    {item.is_subquestion === 0 ?
                                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d={commonSvgPath} /></svg>
                                      : ''}
                                  </div>

                                  {item.question_form_type === 'radio' ?
                                    (
                                      <div className='w-100'>

                                        <div className="mx-3 w-100 input-field">
                                          <div className="row">
                                            <div className="col-md-12" style={{ textAlign: 'left' }}>{item.question_label} {item.is_manadatory === 1 ? <span className="checkout__input--label__star">(Mandatory)</span> : ''} </div>
                                            {item.question_form_option.map((item1) => {
                                              return (
                                                <div className=" col-12 col-sm-6 col-md-12 col-lg-4 col-xl-4 col-xxl-4 my-2 plans">
                                                  <label className="plan basic-plan">
                                                    <input name="event_type"
                                                      type="radio"
                                                      className="btn-check"
                                                      disabled
                                                    />
                                                    <div className="plan-content">
                                                      <img loading="lazy" src="https://raw.githubusercontent.com/ismailvtl/ismailvtl.github.io/master/images/life-saver-img.svg" alt="" />
                                                      <div className="plan-details">
                                                        <span className="h3 m-0">{item1.label}</span>
                                                      </div>
                                                    </div>
                                                  </label>
                                                </div>
                                              )
                                            })}

                                            {/* -------- sub-question -------- */}
                                            {item.sub_questions_array ?
                                              item.sub_questions_array.map((item1, index1) =>
                                              (
                                                <div key={index1}>

                                                  <div className="mx-3 w-100 input-field">
                                                    <div className="form-floating mt-3 mb-0">
                                                      <input
                                                        type={item1.question_form_type}
                                                        className="form-control"
                                                        placeholder=""
                                                        disabled
                                                      />
                                                      <label>{item1.question_label} {item1.is_manadatory === 1 ? <span className="checkout__input--label__star">(Mandatory)</span> : ''} </label>
                                                    </div>
                                                  </div>

                                                </div>

                                              )
                                              ) : ''}

                                          </div>
                                        </div>

                                        <div className="mx-3 option-icon">
                                          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={() => { setDeleteConfirmPopup(true); setGeneralFormId(item.general_form_id); }} ><path d={deleteSvgPath} /></svg>
                                        </div>

                                       {/* ---------- quetion show/hide on ticket pdf */}
                                        <div className="mx-3 option-icon-edit" style={{width: "35px", height: "35px"}} title='Show on pdf'>
                                            <input type="checkbox" id="show_on_ticket_pdf" name="show_on_ticket_pdf" defaultValue={item.show_on_ticket_pdf} defaultChecked={item.show_on_ticket_pdf === 1 ? true : false} 
                                            onClick={() => { setTicketConfirmPopup(true); setGeneralFormId(item.general_form_id); setShowRemoveTicketPdf(item.show_on_ticket_pdf); setSubQuestionFlag(item.child_question_ids);  }}/>
                                        </div>

                                        <div className="mx-3 option-icon-status" title='Question Status'>
                                          <div className="form-check form-switch d-flex align-items-center justify-content-end my-1">
                                                <input className="form-check-input d-flex justify-content-end" type="checkbox" role="switch"
                                                value={item.question_status}
                                                checked={item.question_status}
                                                onChange={(e) => setEventStatus(!EventStatus)} 
                                                onClick={(e) => { setCommId(item.id); handleComm(item.id, 'change_status'); setActionFlag('change_status'); setevntStatus(item.question_status); seteventIndex(index); setFormKey(key);  }} />
                                                
                                            </div> 
                                        </div>

                                      </div>

                                    )
                                    : ''
                                  }

                                </div>
                              </div>
                            </>

                          ) : ''

                    )}
                  </div>
                ))
                  :
                  (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-20 no-ticket">
                      <div className="row coupon">
                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 left">

                        </div>
                        <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6 col-xxl-8 center">
                          <div>
                            <p className="section__header--title h4">No Questions Added</p>
                            <hr />
                            <p className="h4">Please click on add " new questions" button to add new questions</p>
                            <br />
                            <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={() => { setAddGeneralQuestions(true); }} style={{ cursor: 'pointer' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                              New Questions
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 right">
                          <div>

                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }

              </div>

              <div className="checkout__content--step__footer d-flex align-items-center justify-content-end gap-4">
                <div className="continue__shipping--btn secondary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(4) }} style={{ cursor: 'pointer' }}>Back</div>

                <input type="submit" name="command" value="Save & Next (6/11)" className="continue__shipping--btn primary__btn border-radius-5" onClick={(e) => { props.setEventResponceTabs(6); }} />
              </div>

            </div>
          </form>
        }

      </div>
    </>
  )
}

export default FormQuestions